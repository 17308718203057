.HomeFirstSec {
    overflow: hidden !important;

    .RightCol {
        position: relative;
        height: 400px;

        .topImageDiv {
            position: absolute;
            transform: rotate(45deg) translateY(10px);
            bottom: 26%;
            left: 19px;

            .TopImg {
                border-top: 10px solid #ffffff;
                border-right: 10px solid #ffffff;
                width: 250px;
                height: 250px;
            }
        }

        .bottmImg {
            width: 80%;
        }
    }

    .nextPrevCol {
        text-align: end;

        .horizontalLine {
            border-right: 6px solid #062666;
        }

        button {
            border: none;
            background-color: transparent;
        }
    }

    h4 {
        color: #062666;
    }

    .homeImgs {
        border-radius: 50px;
    }

    p {
        color: #000000;
    }

}


.academicsSec {
    .card {
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        position: relative;

        .readMoreBtn {
            width: 100%;
            padding: 14px 0px;
            border: none;
            font-weight: 600;
            background-color: #e9e8e8;
            color: #062666;
            transition: 0.3s;
        }

        .badgeLogoDiv {
            width: 60px;
            height: 60px;
            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
            text-align: center;
            position: absolute;
            right: 23px;
            top: 46%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .card:hover {
        .readMoreBtn {
            background-color: #062666;
            color: #ffffff;
        }
    }
}

.visionMissionSec {
    .commonMAinCol {
        padding: 0px;
        height: 100% !important;

        .commonDiv {
            display: flex;
        }

        .commonDiv>div {
            width: 50%;
        }

        .commoncolorDiv {
            h5 {
                color: #ffffff !important;
            }

            p {
                color: #ffffff;
            }

            a {
                color: #ffffff !important;
            }
        }

        .visionCard {
            background: url('../style/../../assets/images/home/vision.jpg');
            overflow: hidden !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
        }

        .VisionColorDiv {
            background-color: #8CC63F;
        }

        .artCraftCard {
            background: url('../style/../../assets/images/home/mission.jpg');
            overflow: hidden !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;


        }

        .artCraftColorDiv {
            background-color: #EC008C;
        }

        .curriculumCard {
            background: url('../style/../../assets/images/home/circulam.jpg');
            overflow: hidden !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;


        }

        .curriculumColorDiv {
            background-color: #ED1C24;
        }

        .LeadershipCard {
            background: url('../style/../../assets/images/home/motto.jpg');
            overflow: hidden !important;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
        }

        .LeadershipColorDiv {
            background-color: #A3238E;
        }
    }
}



.EventsSec {
    .card {
        border: none;
        border-radius: 0px !important;

        .eventImages {
            height: 300px;
        }

        .card-img-overlay {
            background-color: #0000004d;

            h4,
            h6, p {
                color: #ffffff;
            }
        }
    }


    .tab {
        overflow: hidden;
    }

    .tab button {
        padding: 14px 25px;
        border: none;
        font-size: 14px;
        color: #000000;
        background-color: #e4e4e4;
        transition: 0.3s;
    }

    .tab button:hover {
        background-color: #062666;
        color: #ffffff;
    }

    .tab button.active {
        padding: 14px 25px;
        border: none;
        font-size: 14px;
        background-color: #062666;
        color: #ffffff;
        transition: 0.3s;
    }

    .tabcontent {
        display: none;
        padding: 6px 12px;
        border: none;
         
    }
}

.gallerySec {
    .allGalleryImg {
        border-radius: 10px;
        box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 15%);
    }
}

.comingSoonSec {
    .helloCard {
        height: 90vh !important;
    }

    h1 {
        color: #000000 !important;
        letter-spacing: 16px;
    }

    .allHeaderUL {
        display: flex;
        justify-content: center !important;
    }

    .SchoolLogo {
        width: 20%;
    }

    @media only screen and (min-width: 1px) and (max-width: 570px) {
        .SchoolLogo {
            width: 40%;
        }

        .helloCard {
            height: 90vh !important;
        }
    }
}