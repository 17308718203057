.footer_sec{
    padding: 30px 0px !important;
    background-color: #d5d4d4;
    h6{
        color: #062666 !important;
        font-weight: 700;
    }
    p{
        color: #000000 !important;
    }
    a{
        color: #000000 !important;
        font-size: 14px;
    }
    .phone_num{
        color: #000000;
    }
    .ContectTxt{
        color: #062666;
        font-weight: 700;
    }
    .menu_items{
        text-transform: uppercase;
    }
    .menu_items:hover{
        color: #062666 !important;
        padding-left: 10px;
        transition:.6s ease;
    }
     
}

@media only screen and (min-width: 500px) and (max-width: 990px) {
    .footer_sec{
        text-align: center;
    }
}




@media only screen and (min-width: 760px) and (max-width: 990px) {
    .home_header_image{
        width: 20% !important;
    }
}