.academicAccordionSec{
    .accordion{
        .accordion-item {
            border: none !important;
        }
        button {
            color: #000000;
            background-color: #f5f5f5;
            border-radius: 5px;
            margin-top: 10px;
            padding: 25px 20px;
        }
        button:hover{
            background-color: #062666;
            border-radius: 5px;
            color: #ffffff;
        }
        button:focus{
            box-shadow: none !important;
        }
        button:focus-visible{
            outline: none !important;
        }
        .accordion-button:not(.collapsed) {
            background-color: #062666 !important;
            box-shadow: none !important;
            color: #ffffff;
        }
        .accordion-button::after{
            background-size: 15px !important;
        }
    }
}