.aboutUsSec {
    .RightCol {
        background-color: #062666;

        h2,
        p {
            color: #ffffff;
        }

        .moreBtn {
            color: #ff0012;
            background: transparent;
            border: none;
            padding: 0px;
        }
    }
    .highText{
        color: #062666 !important;
    }

}

.AboutusModel {
    .modal-content {
        text-align: center;
        .modal-header {
            border-bottom: none !important;
            .modal-title {
                color: #062666;
            }
        }
    }
}


.gearup_business {
    .allHrLines{
        border: 1px solid #ffffff;
    }
    .left_col {
        
        background-color: #E6E6E6;
        strong {
             
            color: #062666 !important;
        }
        p{
            color: #000000;
        }
    }

    .right_col {
        
        background-color: #062666;
        p{
            color: #ffffff;
        }
         
        strong {
            color: #ffffff !important;
        }
    }


}


.values_sec {
     

    .value_col {
        background-color: #062666;
        border-top-left-radius: 170px;
        h2{
            color: #ffffff;
        }
    }

    span {
        color: #ea4d44;
    }

    h5 {
        color: #062666;
    }

    p {
        line-height: 30px !important;
        color: #000000;
    }
}