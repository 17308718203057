@media screen and (min-width: 1px) and (max-width: 575px) {
    .visionMissionSec{
        .commonMAinCol{
            padding: 0px 20px;
            .commonDiv {
                flex-direction: column;
                padding: 20px 0px;
                > div {
                    width: 100%;
                }
            }
            .visionCard, .LeadershipCard, .curriculumCard, .artCraftCard{
                height: 300px;
            }
        }
    }
}



@media screen and (min-width: 767px) and (max-width: 990px) {
    .HomeFirstSec{
        .RightCol{
            .topImageDiv {
                left: 81px !important;
                .TopImg{
                    width: 250px;
                    height: 250px;
                }
            }
            .bottmImg{
                width: 60%;
            }
        }
    }
}

@media screen and (min-width: 501px) and (max-width: 767px) {
    .HomeFirstSec{
        .RightCol{
            .topImageDiv {
                left: 81px !important;
                .TopImg{
                    width: 250px;
                    height: 250px;
                }
            }
            .bottmImg{
                width: 60%;
            }
        }
        .nextPrevCol{
            text-align: center;
        }
    }
}


@media screen and (min-width: 1px) and (max-width: 500px) {
    .HomeFirstSec{
        .RightCol{
            height: 100%;
            .TopImg{
                border-radius: 10px;
            }
            .topImageDiv {
                display: none;
            }
            .bottmImg{
                display: none;
            }
            .fullImg{
                display: block !important;
            }
        }
        .nextPrevCol{
            padding-top: 20px;
            text-align: center;
        }
    }
}