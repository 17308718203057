@import "home.scss";
@import "homeMobile.scss";
@import "header.scss";
@import "footer.scss";
@import "contactus.scss";
@import "aboutus.scss";
@import "academics.scss";


a {
    text-decoration: none !important;
}
p{
    font-size: 16px;
    color: #676767;
    word-spacing: 1px;
    letter-spacing: 0.5px;
}
h1{
    color: #062666;
    font-size: 50px !important;
    font-weight: 600;
}
h2 {
    color: #062666;
}
h5{
    color: #062666;
}
.allBtns{
    padding: 10px 25px;
    border: none;
    font-size: 14px;
    background-color: #062666;
    color: #ffffff;
    transition: 0.3s;
}
.allBtns:hover {opacity: 0.6}

.commmonSecSeperate{
    background-color: #efefef;
}
section {
    padding: 70px 0px;
}

.allFirstSections {
    padding: 140px 0px 70px 0px;
}

.subHeading{
    font-size: 12px;
    letter-spacing: 5px;
}

.contactBannerSec{
    background-color: #062666;
    .ContactBannerImgCol{
        text-align: end;
        img{
            width: 80%;
        }
    }
    .getin_touch_btn{
        background-color: transparent;
        border: 1px solid #ffffff;
        color: #ffffff;
        padding: 10px 25px;
    }
}

@media screen and (min-width: 1px) and (max-width: 609px) {
    .contactBannerSec{
        .ContactBannerImgCol{
            text-align: center;
            img{
                width: 60%;
            }
        }
    }
}



@media screen and (min-width: 786px) and (max-width: 1300px) {
    .allFirstSections {
        padding: 120px 0px 70px 0px;
    }
}

@media screen and (min-width: 610px) and (max-width: 787px) {
    .allFirstSections {
        padding: 100px 0px 70px 0px;
    }
}

@media screen and (min-width: 1px) and (max-width: 609px) {
    .allFirstSections {
        padding: 80px 0px 40px 0px;
    }
    section {
        padding: 40px 0px;
    }
}



















