@import "bootstrap/dist/css/bootstrap.min.css";
@import "utilits/style/index.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #062666;
  outline: 1px solid #062666;
  height: 30px;
  border-radius: 12px;
}



blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}


input{
  width: 100%;
  border: none;
  background-color: #ececec;
  padding: 8px 10px;
  border-radius: 5px !important;
}
input:focus{
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select{
  width: 100%;
  border: none;
  background-color: #ececec;
  padding: 8px 10px;
  border-radius: 5px !important;
}
select:focus {
  outline: none;
}

textarea {
  width: 100%;
  border: none;
  background-color: #ececec;
  padding: 8px 10px;
  border-radius: 5px !important;
  resize: none;
}
textarea:focus-visible {
  outline: none;
}

.all_check_box {
  width: auto !important;
}


.whatsapp {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.whatsapp a {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: relative;
}

.whatsapp a img {
  width: 35px;
  height: 35px;
}