.header_section {
    position: fixed !important;
    width: 100%;
    z-index: 1020 !important;
    background-color: #ffffff;
    .navbar-collapse {
        justify-content: center !important;
    }

    .Links {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        color: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
    }
    .MainLogo{
        width: 22%;
    }
    .StripLogo{
        width: 75%;
        height: 100px;
    }
    .NavUl {
        margin: auto;
        display: flex;
        list-style: none;
        padding: 0;

        .navLi {
            list-style-type: none !important;
            padding: 8px 0px;
        }
    }
    
    .MenuCircle {
        border: 2px solid #000000;
        border-radius: 50%;
        padding: 5px;
    }

    .MenuTxt {
        font-size: 13px;
        cursor: pointer;
        display: none;
    }

    .MainNavBar {
        background-color: #062666;
        padding: 0px;
    }

    

}

.offCanvass {
    background-color: #ffffff !important;
    width: 30% !important;
    .Links {
        text-decoration: none !important;
        color: #000000;
    }
    .Links:hover {
        text-decoration: none !important;
        color: #062666;
    }
}



@media screen and (min-width: 1px) and (max-width: 990px) {
    .navbar-toggler{
        display: none;
    }
    .PhoneDiv{
        display: none !important;
    }
}

@media screen and (min-width: 786px) and (max-width: 990px) {
    .MainLogo{
        width: 30% !important;
    }
    .StripLogo{
        height: 70px !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .displaySocialMediaLinks{
        display: none;
    }
    .MainNavBar{
        display: none;
    }
    .MenuImg{
        border: 1px solid;
        border-image: linear-gradient(to right, #870b13, #062868) 1; 
        padding: 5px;
        border-radius: 50% !important;
    }
    .MenuTxt{
        display: block !important;
    }
    
}


@media screen and (min-width: 440px) and (max-width: 767px) {
    .MainLogo{
        width: 30% !important;
    }
    
}

@media screen and (min-width: 490px) and (max-width: 630px) {
    .offCanvass{
        width: 40% !important;
    }
}

@media screen and (min-width: 380px) and (max-width: 490px) {
    .offCanvass{
        width: 50% !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 379px) {
    .offCanvass{
        width: 70% !important;
    }
}


@media screen and (min-width: 1px) and (max-width: 439px) {
    .MainLogo{
        width: 40% !important;
    }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
    .StripLogo{
        height: 60px !important;
    }
}



@media screen and (min-width: 1px) and (max-width: 575px) {
    .StripLogo{
       width: 160% !important;
    }
}
